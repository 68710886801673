<template>
    <section :class="$route.name == 'tendero.carrito' ? '' : ''">
        <div class="d-none select-responsive mx-0 py-2">
            <div class="col-4">
                <el-select v-model="id_categoria" placeholder="Selecciona una categoria" class="w-100">
                    <el-option
                    v-for="(item, idx) in categorias"
                    :key="idx"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0">
            <div class="menu-prinicipal">
                <div class="row mx-0 pb-2">
                    <div class="col px-0 my-auto">
                        <el-input v-model="buscar" clearable placeholder="Buscar producto" class="br-20" />
                    </div>
                </div>
                <div
                class="border px-0 listar-productos col br-10 overflow-auto custom-scroll bg-white"
                :style="`width:400px;height:calc(100vh - ${$route.name == 'tendero.carrito' ? '158' : '186'}px);`"
                >
                    <div
                    v-for="(c, idx) in categorias" :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:54px;"
                    @click="id_categoria = c.id, activo=idx"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${activo==idx?'bg-general':''}`" />
                        <div class="col tres-puntos f-15">
                            <div class="row mx-0 text-general f-600">
                                <p class="tres-puntos">{{ c.nombre }}</p>
                            </div>
                        </div>
                        <div :class="`br-10 px-2 text-center f-17 my-auto ${activo==idx?'bg-general3 text-white':''}`" style="min-width:40px;">
                            <span>{{ c.cant_productos }}</span>
                        </div>
                        <i :class="`icon-angle-right f-18 ${activo==idx?'text-general':''}`" />
                    </div>
                    <content-loader v-show="loading" />

                    <!-- sin coincidencias -->
                    <div v-if="buscar.length>0 && categorias.length === 0" class="row mx-0 my-4 align-content-center justify-center">
                        <div class="col-9 text-center">
                            <p class="mb-0 text-muted2 f-17 f-600">
                                No hemos encontrado resultados.
                            </p>
                            <div class="row mx-0 justify-center f-60">
                                <img src="/img/icons/product-1.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 border br-t-10 bg-white menu-prinicipal-carrito ml-3">
                <listado :categoria="id_categoria" />
            </div>
        </div>
        <modal-respuesta-pago-payu ref="modalRespuestaTransaccion" />
    </section>
</template>

<script>
import Almacen from "~/services/almacen/almacen"
import {mapGetters} from 'vuex'
export default {
    metaInfo: () =>({ title: 'Productos'}),
    components: {
        listado: () => import('./partials/listado'),
    },
    data(){
        return {
            activo: -1,
            id_categoria:null,
            categorias:[],
            loading:false,
            value: ''
        }
    },
    computed:{
        ...mapGetters({
            query: 'pedidos/pedidos_tendero/query',
        }),
        param(){
            return this.$route.params.stock
        },
        buscar: {
            // getter
            get: function(){
                return this.query
            },
            // setter
            set: function(newValue){
                this.$store.commit('pedidos/pedidos_tendero/set_query',newValue)

                this.id_categoria = null
                this.activo = -1

                if(newValue.length > 2){
                    const addInfo = () => {
                        this.lista_categorias()
                    }
                    this.delay(addInfo)

                }
                if(newValue.length === 0){
                    const addInfo = () => {
                        this.lista_categorias()
                    }
                    this.delay(addInfo)
                }
            }
        }
    },
    mounted(){
        if(this.$route.query.payu == 1){
            this.$refs.modalRespuestaTransaccion.toggle()
        }
        this.lista_categorias()
    },
    destroyed(){
        this.$store.commit('pedidos/pedidos_tendero/vaciar_productos')
    },
    methods: {
        async lista_categorias(){
            try {
                this.loading = true
                let params = {
                    query:this.query
                }
                const {data} = await Almacen.categorias(params)
                this.categorias = data.categorias

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal-carrito{
    width: calc(100vw - 545px);
}

@media(max-width:980px){
    .menu-prinicipal{
        display: none !important;
    }
    .menu-prinicipal-carrito{
        width: calc(100vw - 48px) !important;
    }
    .select-responsive{
        display: block !important;
    }
}

@media (max-width: 1224px) {
    .listar-productos{
        width: 340px !important;
    }
    .menu-prinicipal-carrito{
        width: calc(100vw - 476px);
    }
}
</style>
